import BlogList from "../containers/BlogList"
import ChangeYourLife from "../containers/ChangeYourLife"
import { ContentWrapper } from "../containers/elegant.style"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"

const BlogListPage = ({ data: { allContentfulBlogPost }, location }) => {
  const url = location.href ? location.href : ""
  return (
    <>
      <SEO
        title="Elegant Beauty Clinic Blog"
        shortDesc="Elegant Beauty Clinic"
        url={url}
        description="Browse our range of skin, health and weigh loss related articles, advice and tips on keeping your skin looking young, healthy and full of glow."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle title="The Elegant Blog" subtitle="News / Tips / Advice" />
          <BlogList blogListContent={allContentfulBlogPost} />
          <ChangeYourLife />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default BlogListPage

export const pageQuery = graphql`
  query BlogPostsByCategory($category: String!) {
    allContentfulBlogPost(
      filter: { blogPostCategory: { eq: $category } }
      sort: { fields: updatedAt }
    ) {
      edges {
        node {
          blogPostCategory
          blogPostSlug
          blogPostTitle
          blogPostMainImage {
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          blogPostIntroText {
            blogPostIntroText
          }
          createdAt(formatString: "MMMM DD, y")
        }
      }
    }
  }
`
